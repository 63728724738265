/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/

/* emoji-text - имг эмоджи которые приходят с сервера */
.emoji-text {
  margin-right: 3px;
}

.icon {
  display: inline-block;
  vertical-align: middle;
}

.icon-f09fa490 {
  background-image: url(sprite.png);
  background-position: -16px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09fa491 {
  background-image: url(sprite.png);
  background-position: -128px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09fa492 {
  background-image: url(sprite.png);
  background-position: 0px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09fa493 {
  background-image: url(sprite.png);
  background-position: -16px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09fa494 {
  background-image: url(sprite.png);
  background-position: -32px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09fa495 {
  background-image: url(sprite.png);
  background-position: -32px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09fa496 {
  background-image: url(sprite.png);
  background-position: 0px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09fa497 {
  background-image: url(sprite.png);
  background-position: -16px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09fa498 {
  background-image: url(sprite.png);
  background-position: -32px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09fa499 {
  background-image: url(sprite.png);
  background-position: -48px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09fa49a {
  background-image: url(sprite.png);
  background-position: -48px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09fa49b {
  background-image: url(sprite.png);
  background-position: -48px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09fa49c {
  background-image: url(sprite.png);
  background-position: 0px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09fa49d {
  background-image: url(sprite.png);
  background-position: -16px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09fa49e {
  background-image: url(sprite.png);
  background-position: -32px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09fa49f {
  background-image: url(sprite.png);
  background-position: -48px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09fa580 {
  background-image: url(sprite.png);
  background-position: -64px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09fa581 {
  background-image: url(sprite.png);
  background-position: -64px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09fa582 {
  background-image: url(sprite.png);
  background-position: -64px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09fa583 {
  background-image: url(sprite.png);
  background-position: -64px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09fa584 {
  background-image: url(sprite.png);
  background-position: 0px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09fa585 {
  background-image: url(sprite.png);
  background-position: -16px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09fa587 {
  background-image: url(sprite.png);
  background-position: -32px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09fa588 {
  background-image: url(sprite.png);
  background-position: -48px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09fa589 {
  background-image: url(sprite.png);
  background-position: -64px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09fa58a {
  background-image: url(sprite.png);
  background-position: -80px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09fa58b {
  background-image: url(sprite.png);
  background-position: -80px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09fa58c {
  background-image: url(sprite.png);
  background-position: -80px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09fa58d {
  background-image: url(sprite.png);
  background-position: -80px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09fa58e {
  background-image: url(sprite.png);
  background-position: -80px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09fa58f {
  background-image: url(sprite.png);
  background-position: 0px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09fa590 {
  background-image: url(sprite.png);
  background-position: -16px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09fa591 {
  background-image: url(sprite.png);
  background-position: -32px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09fa592 {
  background-image: url(sprite.png);
  background-position: -48px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09fa593 {
  background-image: url(sprite.png);
  background-position: -64px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09fa594 {
  background-image: url(sprite.png);
  background-position: -80px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09fa595 {
  background-image: url(sprite.png);
  background-position: -96px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09fa596 {
  background-image: url(sprite.png);
  background-position: -96px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09fa597 {
  background-image: url(sprite.png);
  background-position: -96px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09fa598 {
  background-image: url(sprite.png);
  background-position: -96px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09fa599 {
  background-image: url(sprite.png);
  background-position: -96px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09fa59a {
  background-image: url(sprite.png);
  background-position: -96px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09fa59b {
  background-image: url(sprite.png);
  background-position: 0px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09fa59c {
  background-image: url(sprite.png);
  background-position: -16px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09fa59d {
  background-image: url(sprite.png);
  background-position: -32px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09fa59e {
  background-image: url(sprite.png);
  background-position: -48px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09fa59f {
  background-image: url(sprite.png);
  background-position: -64px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09fa680 {
  background-image: url(sprite.png);
  background-position: -80px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09fa681 {
  background-image: url(sprite.png);
  background-position: -96px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09fa682 {
  background-image: url(sprite.png);
  background-position: -112px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09fa683 {
  background-image: url(sprite.png);
  background-position: -112px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09fa684 {
  background-image: url(sprite.png);
  background-position: -112px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09fa685 {
  background-image: url(sprite.png);
  background-position: -112px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09fa686 {
  background-image: url(sprite.png);
  background-position: -112px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09fa687 {
  background-image: url(sprite.png);
  background-position: -112px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09fa688 {
  background-image: url(sprite.png);
  background-position: -112px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09fa689 {
  background-image: url(sprite.png);
  background-position: 0px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09fa68a {
  background-image: url(sprite.png);
  background-position: -16px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09fa68b {
  background-image: url(sprite.png);
  background-position: -32px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09fa68c {
  background-image: url(sprite.png);
  background-position: -48px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09fa68d {
  background-image: url(sprite.png);
  background-position: -64px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09fa68e {
  background-image: url(sprite.png);
  background-position: -80px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09fa68f {
  background-image: url(sprite.png);
  background-position: -96px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09fa690 {
  background-image: url(sprite.png);
  background-position: -112px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09fa691 {
  background-image: url(sprite.png);
  background-position: -128px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09fa692 {
  background-image: url(sprite.png);
  background-position: -128px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09fa693 {
  background-image: url(sprite.png);
  background-position: -128px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09fa694 {
  background-image: url(sprite.png);
  background-position: 0px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09fa695 {
  background-image: url(sprite.png);
  background-position: -128px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09fa696 {
  background-image: url(sprite.png);
  background-position: -128px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09fa697 {
  background-image: url(sprite.png);
  background-position: -128px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09fa698 {
  background-image: url(sprite.png);
  background-position: -128px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09fa699 {
  background-image: url(sprite.png);
  background-position: 0px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa69a {
  background-image: url(sprite.png);
  background-position: -16px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa69b {
  background-image: url(sprite.png);
  background-position: -32px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa69c {
  background-image: url(sprite.png);
  background-position: -48px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa69d {
  background-image: url(sprite.png);
  background-position: -64px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa69e {
  background-image: url(sprite.png);
  background-position: -80px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa69f {
  background-image: url(sprite.png);
  background-position: -96px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa780 {
  background-image: url(sprite.png);
  background-position: -112px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa781 {
  background-image: url(sprite.png);
  background-position: -128px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa782 {
  background-image: url(sprite.png);
  background-position: -144px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09fa790 {
  background-image: url(sprite.png);
  background-position: -144px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09fa791 {
  background-image: url(sprite.png);
  background-position: -144px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09fa792 {
  background-image: url(sprite.png);
  background-position: -144px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09fa793 {
  background-image: url(sprite.png);
  background-position: -144px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09fa794 {
  background-image: url(sprite.png);
  background-position: -144px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09fa795 {
  background-image: url(sprite.png);
  background-position: -144px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09fa796e2808de29980 {
  background-image: url(sprite.png);
  background-position: -144px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09fa796e2808de29982 {
  background-image: url(sprite.png);
  background-position: -144px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa797e2808de29980 {
  background-image: url(sprite.png);
  background-position: 0px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa797e2808de29982 {
  background-image: url(sprite.png);
  background-position: -16px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa797f09f8fbbe2808de29980 {
  background-image: url(sprite.png);
  background-position: -32px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa797f09f8fbbe2808de29982 {
  background-image: url(sprite.png);
  background-position: -48px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa797f09f8fbce2808de29980 {
  background-image: url(sprite.png);
  background-position: -64px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa797f09f8fbce2808de29982 {
  background-image: url(sprite.png);
  background-position: -80px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa797f09f8fbde2808de29980 {
  background-image: url(sprite.png);
  background-position: -96px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa797f09f8fbde2808de29982 {
  background-image: url(sprite.png);
  background-position: -112px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa797f09f8fbee2808de29980 {
  background-image: url(sprite.png);
  background-position: -128px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa797f09f8fbee2808de29982 {
  background-image: url(sprite.png);
  background-position: -144px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa797f09f8fbfe2808de29980 {
  background-image: url(sprite.png);
  background-position: -160px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09fa797f09f8fbfe2808de29982 {
  background-image: url(sprite.png);
  background-position: -160px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09fa798e2808de29980 {
  background-image: url(sprite.png);
  background-position: -160px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09fa798e2808de29982 {
  background-image: url(sprite.png);
  background-position: -160px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09fa798f09f8fbbe2808de29980 {
  background-image: url(sprite.png);
  background-position: -160px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09fa798f09f8fbbe2808de29982 {
  background-image: url(sprite.png);
  background-position: -160px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09fa798f09f8fbce2808de29980 {
  background-image: url(sprite.png);
  background-position: -160px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09fa798f09f8fbce2808de29982 {
  background-image: url(sprite.png);
  background-position: -160px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09fa798f09f8fbde2808de29980 {
  background-image: url(sprite.png);
  background-position: -160px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa798f09f8fbde2808de29982 {
  background-image: url(sprite.png);
  background-position: -160px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa798f09f8fbee2808de29980 {
  background-image: url(sprite.png);
  background-position: 0px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa798f09f8fbee2808de29982 {
  background-image: url(sprite.png);
  background-position: -16px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa798f09f8fbfe2808de29980 {
  background-image: url(sprite.png);
  background-position: -32px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa798f09f8fbfe2808de29982 {
  background-image: url(sprite.png);
  background-position: -48px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa799e2808de29980 {
  background-image: url(sprite.png);
  background-position: -64px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa799e2808de29982 {
  background-image: url(sprite.png);
  background-position: -80px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa79ae2808de29980 {
  background-image: url(sprite.png);
  background-position: -96px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa79ae2808de29982 {
  background-image: url(sprite.png);
  background-position: -112px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa79be2808de29980 {
  background-image: url(sprite.png);
  background-position: -128px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa79be2808de29982 {
  background-image: url(sprite.png);
  background-position: -144px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa79ce2808de29980 {
  background-image: url(sprite.png);
  background-position: -160px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa79ce2808de29982 {
  background-image: url(sprite.png);
  background-position: -176px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09fa79de2808de29980 {
  background-image: url(sprite.png);
  background-position: -176px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09fa79de2808de29982 {
  background-image: url(sprite.png);
  background-position: -176px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09fa79ee2808de29980 {
  background-image: url(sprite.png);
  background-position: -176px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09fa79ee2808de29982 {
  background-image: url(sprite.png);
  background-position: -176px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09fa79fe2808de29980 {
  background-image: url(sprite.png);
  background-position: -176px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09fa79fe2808de29982 {
  background-image: url(sprite.png);
  background-position: -176px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7b9 {
  background-image: url(sprite.png);
  background-position: -176px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7ba {
  background-image: url(sprite.png);
  background-position: -176px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7bb {
  background-image: url(sprite.png);
  background-position: -176px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7bc {
  background-image: url(sprite.png);
  background-position: -176px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7bd {
  background-image: url(sprite.png);
  background-position: 0px -176px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7be {
  background-image: url(sprite.png);
  background-position: -16px -176px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7bf {
  background-image: url(sprite.png);
  background-position: -32px -176px;
  width: 16px;
  height: 16px;
}

.icon-f09f9492 {
  background-image: url(sprite1.png);
  background-position: -16px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09f9493 {
  background-image: url(sprite1.png);
  background-position: -160px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09f9494 {
  background-image: url(sprite1.png);
  background-position: 0px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09f9495 {
  background-image: url(sprite1.png);
  background-position: -16px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09f9496 {
  background-image: url(sprite1.png);
  background-position: -32px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09f9497 {
  background-image: url(sprite1.png);
  background-position: -32px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09f9498 {
  background-image: url(sprite1.png);
  background-position: 0px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09f9499 {
  background-image: url(sprite1.png);
  background-position: -16px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09f9589 {
  background-image: url(sprite1.png);
  background-position: -32px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09f9590 {
  background-image: url(sprite1.png);
  background-position: -48px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09f9591 {
  background-image: url(sprite1.png);
  background-position: -48px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09f9592 {
  background-image: url(sprite1.png);
  background-position: -48px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09f9593 {
  background-image: url(sprite1.png);
  background-position: 0px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09f9594 {
  background-image: url(sprite1.png);
  background-position: -16px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09f9595 {
  background-image: url(sprite1.png);
  background-position: -32px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09f9596 {
  background-image: url(sprite1.png);
  background-position: -48px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09f9597 {
  background-image: url(sprite1.png);
  background-position: -64px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09f9598 {
  background-image: url(sprite1.png);
  background-position: -64px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09f9599 {
  background-image: url(sprite1.png);
  background-position: -64px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09f9687 {
  background-image: url(sprite1.png);
  background-position: -64px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09f9690 {
  background-image: url(sprite1.png);
  background-position: 0px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09f9695 {
  background-image: url(sprite1.png);
  background-position: -16px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09f9696 {
  background-image: url(sprite1.png);
  background-position: -32px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09f9782 {
  background-image: url(sprite1.png);
  background-position: -48px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09f9783 {
  background-image: url(sprite1.png);
  background-position: -64px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09f9784 {
  background-image: url(sprite1.png);
  background-position: -80px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09f9791 {
  background-image: url(sprite1.png);
  background-position: -80px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09f9792 {
  background-image: url(sprite1.png);
  background-position: -80px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09f9793 {
  background-image: url(sprite1.png);
  background-position: -80px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09f9880 {
  background-image: url(sprite1.png);
  background-position: -80px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09f9881 {
  background-image: url(sprite1.png);
  background-position: 0px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09f9882 {
  background-image: url(sprite1.png);
  background-position: -16px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09f9883 {
  background-image: url(sprite1.png);
  background-position: -32px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09f9884 {
  background-image: url(sprite1.png);
  background-position: -48px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09f9885 {
  background-image: url(sprite1.png);
  background-position: -64px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09f9886 {
  background-image: url(sprite1.png);
  background-position: -80px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09f9887 {
  background-image: url(sprite1.png);
  background-position: -96px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09f9888 {
  background-image: url(sprite1.png);
  background-position: -96px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09f9889 {
  background-image: url(sprite1.png);
  background-position: -96px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09f9890 {
  background-image: url(sprite1.png);
  background-position: -96px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09f9891 {
  background-image: url(sprite1.png);
  background-position: -96px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09f9892 {
  background-image: url(sprite1.png);
  background-position: -96px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09f9893 {
  background-image: url(sprite1.png);
  background-position: 0px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09f9894 {
  background-image: url(sprite1.png);
  background-position: -16px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09f9895 {
  background-image: url(sprite1.png);
  background-position: -32px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09f9896 {
  background-image: url(sprite1.png);
  background-position: -48px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09f9897 {
  background-image: url(sprite1.png);
  background-position: -64px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09f9898 {
  background-image: url(sprite1.png);
  background-position: -80px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09f9899 {
  background-image: url(sprite1.png);
  background-position: -96px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09f9980 {
  background-image: url(sprite1.png);
  background-position: -112px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09f9981 {
  background-image: url(sprite1.png);
  background-position: -112px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09f9982 {
  background-image: url(sprite1.png);
  background-position: -112px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09f9983 {
  background-image: url(sprite1.png);
  background-position: -112px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09f9984 {
  background-image: url(sprite1.png);
  background-position: -112px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09f9985 {
  background-image: url(sprite1.png);
  background-position: -112px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09f9985e2808de29982 {
  background-image: url(sprite1.png);
  background-position: -112px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09f9986 {
  background-image: url(sprite1.png);
  background-position: 0px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09f9986e2808de29982 {
  background-image: url(sprite1.png);
  background-position: -16px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09f9987 {
  background-image: url(sprite1.png);
  background-position: -32px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09f9987e2808de29980 {
  background-image: url(sprite1.png);
  background-position: -48px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09f9988 {
  background-image: url(sprite1.png);
  background-position: -64px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09f9989 {
  background-image: url(sprite1.png);
  background-position: -80px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4a0 {
  background-image: url(sprite1.png);
  background-position: -96px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4a1 {
  background-image: url(sprite1.png);
  background-position: -112px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4a2 {
  background-image: url(sprite1.png);
  background-position: -128px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4a3 {
  background-image: url(sprite1.png);
  background-position: -128px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4a4 {
  background-image: url(sprite1.png);
  background-position: -128px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4a5 {
  background-image: url(sprite1.png);
  background-position: -128px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4a6e2808de29980 {
  background-image: url(sprite1.png);
  background-position: -128px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4a6e2808de29982 {
  background-image: url(sprite1.png);
  background-position: -128px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4a7 {
  background-image: url(sprite1.png);
  background-position: -128px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4a8 {
  background-image: url(sprite1.png);
  background-position: -128px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4a9 {
  background-image: url(sprite1.png);
  background-position: 0px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4aa {
  background-image: url(sprite1.png);
  background-position: -16px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4ab {
  background-image: url(sprite1.png);
  background-position: -32px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4ac {
  background-image: url(sprite1.png);
  background-position: -48px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4ad {
  background-image: url(sprite1.png);
  background-position: -64px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4ae {
  background-image: url(sprite1.png);
  background-position: -80px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4af {
  background-image: url(sprite1.png);
  background-position: -96px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b0 {
  background-image: url(sprite1.png);
  background-position: -112px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b1 {
  background-image: url(sprite1.png);
  background-position: -128px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b2 {
  background-image: url(sprite1.png);
  background-position: -144px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b3 {
  background-image: url(sprite1.png);
  background-position: -144px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b4 {
  background-image: url(sprite1.png);
  background-position: -144px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b5 {
  background-image: url(sprite1.png);
  background-position: -144px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b6 {
  background-image: url(sprite1.png);
  background-position: -144px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b7e2808de29980 {
  background-image: url(sprite1.png);
  background-position: -144px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b7e2808de29982 {
  background-image: url(sprite1.png);
  background-position: -144px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b8e2808de29980 {
  background-image: url(sprite1.png);
  background-position: -144px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b8e2808de29982 {
  background-image: url(sprite1.png);
  background-position: -144px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b8f09f8fbbe2808de29980 {
  background-image: url(sprite1.png);
  background-position: 0px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b8f09f8fbbe2808de29982 {
  background-image: url(sprite1.png);
  background-position: -16px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b8f09f8fbce2808de29980 {
  background-image: url(sprite1.png);
  background-position: -32px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b8f09f8fbce2808de29982 {
  background-image: url(sprite1.png);
  background-position: -48px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b8f09f8fbde2808de29980 {
  background-image: url(sprite1.png);
  background-position: -64px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b8f09f8fbde2808de29982 {
  background-image: url(sprite1.png);
  background-position: -80px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b8f09f8fbee2808de29980 {
  background-image: url(sprite1.png);
  background-position: -96px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b8f09f8fbee2808de29982 {
  background-image: url(sprite1.png);
  background-position: -112px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b8f09f8fbfe2808de29980 {
  background-image: url(sprite1.png);
  background-position: -128px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b8f09f8fbfe2808de29982 {
  background-image: url(sprite1.png);
  background-position: -144px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b9e2808de29980 {
  background-image: url(sprite1.png);
  background-position: 0px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b9e2808de29982 {
  background-image: url(sprite1.png);
  background-position: -160px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b9f09f8fbbe2808de29980 {
  background-image: url(sprite1.png);
  background-position: -160px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b9f09f8fbbe2808de29982 {
  background-image: url(sprite1.png);
  background-position: -160px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b9f09f8fbce2808de29980 {
  background-image: url(sprite1.png);
  background-position: -160px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b9f09f8fbce2808de29982 {
  background-image: url(sprite1.png);
  background-position: -160px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b9f09f8fbde2808de29980 {
  background-image: url(sprite1.png);
  background-position: -160px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b9f09f8fbde2808de29982 {
  background-image: url(sprite1.png);
  background-position: -160px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b9f09f8fbee2808de29980 {
  background-image: url(sprite1.png);
  background-position: -160px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b9f09f8fbee2808de29982 {
  background-image: url(sprite1.png);
  background-position: -160px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b9f09f8fbfe2808de29980 {
  background-image: url(sprite1.png);
  background-position: 0px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4b9f09f8fbfe2808de29982 {
  background-image: url(sprite1.png);
  background-position: -16px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4ba {
  background-image: url(sprite1.png);
  background-position: -32px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bce2808de29980 {
  background-image: url(sprite1.png);
  background-position: -48px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bce2808de29982 {
  background-image: url(sprite1.png);
  background-position: -64px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bde2808de29980 {
  background-image: url(sprite1.png);
  background-position: -80px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bde2808de29982 {
  background-image: url(sprite1.png);
  background-position: -96px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bdf09f8fbbe2808de29980 {
  background-image: url(sprite1.png);
  background-position: -112px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bdf09f8fbbe2808de29982 {
  background-image: url(sprite1.png);
  background-position: -128px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bdf09f8fbce2808de29980 {
  background-image: url(sprite1.png);
  background-position: -144px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bdf09f8fbce2808de29982 {
  background-image: url(sprite1.png);
  background-position: -160px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bdf09f8fbde2808de29980 {
  background-image: url(sprite1.png);
  background-position: -176px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bdf09f8fbde2808de29982 {
  background-image: url(sprite1.png);
  background-position: -176px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bdf09f8fbee2808de29980 {
  background-image: url(sprite1.png);
  background-position: -176px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bdf09f8fbee2808de29982 {
  background-image: url(sprite1.png);
  background-position: -176px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bdf09f8fbfe2808de29980 {
  background-image: url(sprite1.png);
  background-position: -176px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bdf09f8fbfe2808de29982 {
  background-image: url(sprite1.png);
  background-position: -176px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bee2808de29980 {
  background-image: url(sprite1.png);
  background-position: -176px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bee2808de29982 {
  background-image: url(sprite1.png);
  background-position: -176px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bef09f8fbbe2808de29980 {
  background-image: url(sprite1.png);
  background-position: -176px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bef09f8fbbe2808de29982 {
  background-image: url(sprite1.png);
  background-position: -176px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bef09f8fbce2808de29980 {
  background-image: url(sprite1.png);
  background-position: -176px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bef09f8fbce2808de29982 {
  background-image: url(sprite1.png);
  background-position: 0px -176px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bef09f8fbde2808de29982 {
  background-image: url(sprite1.png);
  background-position: -16px -176px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bef09f8fbee2808de29980 {
  background-image: url(sprite1.png);
  background-position: -32px -176px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bef09f8fbee2808de29982 {
  background-image: url(sprite1.png);
  background-position: -48px -176px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bef09f8fbfe2808de29980 {
  background-image: url(sprite1.png);
  background-position: -64px -176px;
  width: 16px;
  height: 16px;
}
.icon-f09fa4bef09f8fbfe2808de29982 {
  background-image: url(sprite1.png);
  background-position: -80px -176px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5a0 {
  background-image: url(sprite1.png);
  background-position: -96px -176px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5a1 {
  background-image: url(sprite1.png);
  background-position: -112px -176px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5a2 {
  background-image: url(sprite1.png);
  background-position: -128px -176px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5a3 {
  background-image: url(sprite1.png);
  background-position: -144px -176px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5a4 {
  background-image: url(sprite1.png);
  background-position: -160px -176px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5a5 {
  background-image: url(sprite1.png);
  background-position: -176px -176px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5a6 {
  background-image: url(sprite1.png);
  background-position: -192px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5a7 {
  background-image: url(sprite1.png);
  background-position: -192px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5a8 {
  background-image: url(sprite1.png);
  background-position: -192px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5a9 {
  background-image: url(sprite1.png);
  background-position: -192px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5aa {
  background-image: url(sprite1.png);
  background-position: -192px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5ab {
  background-image: url(sprite1.png);
  background-position: -192px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5ac {
  background-image: url(sprite1.png);
  background-position: -192px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5ad {
  background-image: url(sprite1.png);
  background-position: -192px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5ae {
  background-image: url(sprite1.png);
  background-position: -192px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5af {
  background-image: url(sprite1.png);
  background-position: -192px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5b0 {
  background-image: url(sprite1.png);
  background-position: -192px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5b3 {
  background-image: url(sprite1.png);
  background-position: -192px -176px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5b4 {
  background-image: url(sprite1.png);
  background-position: 0px -192px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5b5 {
  background-image: url(sprite1.png);
  background-position: -16px -192px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5b6 {
  background-image: url(sprite1.png);
  background-position: -32px -192px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5ba {
  background-image: url(sprite1.png);
  background-position: -48px -192px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5bc {
  background-image: url(sprite1.png);
  background-position: -64px -192px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5bd {
  background-image: url(sprite1.png);
  background-position: -80px -192px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5be {
  background-image: url(sprite1.png);
  background-position: -96px -192px;
  width: 16px;
  height: 16px;
}
.icon-f09fa5bf {
  background-image: url(sprite1.png);
  background-position: -112px -192px;
  width: 16px;
  height: 16px;
}
.icon-f09fa6a0 {
  background-image: url(sprite1.png);
  background-position: -128px -192px;
  width: 16px;
  height: 16px;
}
.icon-f09fa6a1 {
  background-image: url(sprite1.png);
  background-position: -144px -192px;
  width: 16px;
  height: 16px;
}
.icon-f09fa6a2 {
  background-image: url(sprite1.png);
  background-position: -160px -192px;
  width: 16px;
  height: 16px;
}
.icon-f09fa6b4 {
  background-image: url(sprite1.png);
  background-position: -176px -192px;
  width: 16px;
  height: 16px;
}
.icon-f09fa6b5 {
  background-image: url(sprite1.png);
  background-position: -192px -192px;
  width: 16px;
  height: 16px;
}
.icon-f09fa6b6 {
  background-image: url(sprite1.png);
  background-position: -208px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09fa6b7 {
  background-image: url(sprite1.png);
  background-position: -208px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09fa6b8e2808de29980 {
  background-image: url(sprite1.png);
  background-position: -208px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09fa6b8e2808de29982 {
  background-image: url(sprite1.png);
  background-position: -208px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09fa6b9e2808de29980 {
  background-image: url(sprite1.png);
  background-position: -208px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09fa6b9e2808de29982 {
  background-image: url(sprite1.png);
  background-position: -208px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7a0 {
  background-image: url(sprite1.png);
  background-position: -208px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7a1 {
  background-image: url(sprite1.png);
  background-position: -208px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7a2 {
  background-image: url(sprite1.png);
  background-position: -208px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7a3 {
  background-image: url(sprite1.png);
  background-position: -208px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7a4 {
  background-image: url(sprite1.png);
  background-position: -208px -160px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7a5 {
  background-image: url(sprite1.png);
  background-position: -208px -176px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7a6 {
  background-image: url(sprite1.png);
  background-position: -208px -192px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7a7 {
  background-image: url(sprite1.png);
  background-position: 0px -208px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7a8 {
  background-image: url(sprite1.png);
  background-position: -16px -208px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7a9 {
  background-image: url(sprite1.png);
  background-position: -32px -208px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7aa {
  background-image: url(sprite1.png);
  background-position: -48px -208px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7ab {
  background-image: url(sprite1.png);
  background-position: -64px -208px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7ac {
  background-image: url(sprite1.png);
  background-position: -80px -208px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7ad {
  background-image: url(sprite1.png);
  background-position: -96px -208px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7ae {
  background-image: url(sprite1.png);
  background-position: -112px -208px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7af {
  background-image: url(sprite1.png);
  background-position: -128px -208px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7b0 {
  background-image: url(sprite1.png);
  background-position: -144px -208px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7b1 {
  background-image: url(sprite1.png);
  background-position: -160px -208px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7b2 {
  background-image: url(sprite1.png);
  background-position: -176px -208px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7b3 {
  background-image: url(sprite1.png);
  background-position: -192px -208px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7b4 {
  background-image: url(sprite1.png);
  background-position: -208px -208px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7b5 {
  background-image: url(sprite1.png);
  background-position: -224px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7b6 {
  background-image: url(sprite1.png);
  background-position: -224px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7b7 {
  background-image: url(sprite1.png);
  background-position: -224px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09fa7b8 {
  background-image: url(sprite1.png);
  background-position: -224px -48px;
  width: 16px;
  height: 16px;
}

.icon-f09f8084 {
  background-image: url(sprite2.png);
  background-position: -16px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09f8691 {
  background-image: url(sprite2.png);
  background-position: -160px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09f8692 {
  background-image: url(sprite2.png);
  background-position: 0px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09f8693 {
  background-image: url(sprite2.png);
  background-position: -16px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09f8694 {
  background-image: url(sprite2.png);
  background-position: -32px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09f8695 {
  background-image: url(sprite2.png);
  background-position: -32px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09f8696 {
  background-image: url(sprite2.png);
  background-position: 0px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09f8697 {
  background-image: url(sprite2.png);
  background-position: -16px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09f8698 {
  background-image: url(sprite2.png);
  background-position: -32px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09f8699 {
  background-image: url(sprite2.png);
  background-position: -48px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09f8881 {
  background-image: url(sprite2.png);
  background-position: -48px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09f8882 {
  background-image: url(sprite2.png);
  background-position: -48px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09f8990 {
  background-image: url(sprite2.png);
  background-position: 0px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09f8991 {
  background-image: url(sprite2.png);
  background-position: -16px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09f9080 {
  background-image: url(sprite2.png);
  background-position: -32px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09f9081 {
  background-image: url(sprite2.png);
  background-position: -48px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09f9082 {
  background-image: url(sprite2.png);
  background-position: -64px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09f9083 {
  background-image: url(sprite2.png);
  background-position: -64px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09f9084 {
  background-image: url(sprite2.png);
  background-position: -64px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09f9085 {
  background-image: url(sprite2.png);
  background-position: -64px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09f9086 {
  background-image: url(sprite2.png);
  background-position: 0px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09f9087 {
  background-image: url(sprite2.png);
  background-position: -16px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09f9088 {
  background-image: url(sprite2.png);
  background-position: -32px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09f9089 {
  background-image: url(sprite2.png);
  background-position: -48px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09f9090 {
  background-image: url(sprite2.png);
  background-position: -64px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09f9091 {
  background-image: url(sprite2.png);
  background-position: -80px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09f9092 {
  background-image: url(sprite2.png);
  background-position: -80px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09f9093 {
  background-image: url(sprite2.png);
  background-position: -80px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09f9094 {
  background-image: url(sprite2.png);
  background-position: -80px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09f9095 {
  background-image: url(sprite2.png);
  background-position: -80px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09f9096 {
  background-image: url(sprite2.png);
  background-position: 0px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09f9097 {
  background-image: url(sprite2.png);
  background-position: -16px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09f9098 {
  background-image: url(sprite2.png);
  background-position: -32px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09f9099 {
  background-image: url(sprite2.png);
  background-position: -48px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09f909e {
  background-image: url(sprite2.png);
  background-position: -64px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09f909f {
  background-image: url(sprite2.png);
  background-position: -80px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09f9180 {
  background-image: url(sprite2.png);
  background-position: -96px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09f9181 {
  background-image: url(sprite2.png);
  background-position: -96px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09f9181e2808df09f97a8 {
  background-image: url(sprite2.png);
  background-position: -96px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09f9182 {
  background-image: url(sprite2.png);
  background-position: -96px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09f9183 {
  background-image: url(sprite2.png);
  background-position: -96px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09f9184 {
  background-image: url(sprite2.png);
  background-position: -96px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09f9185 {
  background-image: url(sprite2.png);
  background-position: 0px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09f9186 {
  background-image: url(sprite2.png);
  background-position: -16px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09f9187 {
  background-image: url(sprite2.png);
  background-position: -32px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09f9188 {
  background-image: url(sprite2.png);
  background-position: -48px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09f9189 {
  background-image: url(sprite2.png);
  background-position: -64px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09f918a {
  background-image: url(sprite2.png);
  background-position: -80px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09f918b {
  background-image: url(sprite2.png);
  background-position: -96px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09f918c {
  background-image: url(sprite2.png);
  background-position: -112px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09f918d {
  background-image: url(sprite2.png);
  background-position: -112px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09f918e {
  background-image: url(sprite2.png);
  background-position: -112px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09f918f {
  background-image: url(sprite2.png);
  background-position: -112px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09f9190 {
  background-image: url(sprite2.png);
  background-position: -112px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09f9191 {
  background-image: url(sprite2.png);
  background-position: -112px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09f9192 {
  background-image: url(sprite2.png);
  background-position: -112px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09f9193 {
  background-image: url(sprite2.png);
  background-position: 0px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09f9194 {
  background-image: url(sprite2.png);
  background-position: -16px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09f9195 {
  background-image: url(sprite2.png);
  background-position: -32px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09f9196 {
  background-image: url(sprite2.png);
  background-position: -48px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09f9197 {
  background-image: url(sprite2.png);
  background-position: -64px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09f9198 {
  background-image: url(sprite2.png);
  background-position: -80px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09f9199 {
  background-image: url(sprite2.png);
  background-position: -96px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09f919a {
  background-image: url(sprite2.png);
  background-position: -112px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09f919b {
  background-image: url(sprite2.png);
  background-position: -128px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09f919c {
  background-image: url(sprite2.png);
  background-position: -128px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09f919d {
  background-image: url(sprite2.png);
  background-position: -128px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09f919e {
  background-image: url(sprite2.png);
  background-position: -128px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09f919f {
  background-image: url(sprite2.png);
  background-position: -128px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09f9280 {
  background-image: url(sprite2.png);
  background-position: -128px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09f9281 {
  background-image: url(sprite2.png);
  background-position: -128px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09f9281e2808de29982 {
  background-image: url(sprite2.png);
  background-position: -128px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09f9282 {
  background-image: url(sprite2.png);
  background-position: 0px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09f9282e2808de29980 {
  background-image: url(sprite2.png);
  background-position: -16px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09f9283 {
  background-image: url(sprite2.png);
  background-position: -32px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09f9284 {
  background-image: url(sprite2.png);
  background-position: -48px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09f9285 {
  background-image: url(sprite2.png);
  background-position: -64px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09f9286 {
  background-image: url(sprite2.png);
  background-position: -80px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09f9286e2808de29982 {
  background-image: url(sprite2.png);
  background-position: -96px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09f9287 {
  background-image: url(sprite2.png);
  background-position: -112px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09f9287e2808de29982 {
  background-image: url(sprite2.png);
  background-position: -128px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09f9288 {
  background-image: url(sprite2.png);
  background-position: -144px 0px;
  width: 16px;
  height: 16px;
}
.icon-f09f9289 {
  background-image: url(sprite2.png);
  background-position: -144px -16px;
  width: 16px;
  height: 16px;
}
.icon-f09f928a {
  background-image: url(sprite2.png);
  background-position: -144px -32px;
  width: 16px;
  height: 16px;
}
.icon-f09f928b {
  background-image: url(sprite2.png);
  background-position: -144px -48px;
  width: 16px;
  height: 16px;
}
.icon-f09f928c {
  background-image: url(sprite2.png);
  background-position: -144px -64px;
  width: 16px;
  height: 16px;
}
.icon-f09f928d {
  background-image: url(sprite2.png);
  background-position: -144px -80px;
  width: 16px;
  height: 16px;
}
.icon-f09f928e {
  background-image: url(sprite2.png);
  background-position: -144px -96px;
  width: 16px;
  height: 16px;
}
.icon-f09f928f {
  background-image: url(sprite2.png);
  background-position: -144px -112px;
  width: 16px;
  height: 16px;
}
.icon-f09f9290 {
  background-image: url(sprite2.png);
  background-position: -144px -128px;
  width: 16px;
  height: 16px;
}
.icon-f09f9291 {
  background-image: url(sprite2.png);
  background-position: 0px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09f9292 {
  background-image: url(sprite2.png);
  background-position: -16px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09f9293 {
  background-image: url(sprite2.png);
  background-position: -32px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09f9294 {
  background-image: url(sprite2.png);
  background-position: -48px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09f9295 {
  background-image: url(sprite2.png);
  background-position: -64px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09f9296 {
  background-image: url(sprite2.png);
  background-position: -80px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09f9297 {
  background-image: url(sprite2.png);
  background-position: -96px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09f9298 {
  background-image: url(sprite2.png);
  background-position: -112px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09f9299 {
  background-image: url(sprite2.png);
  background-position: -128px -144px;
  width: 16px;
  height: 16px;
}
.icon-f09f929a {
  background-image: url(sprite2.png);
  background-position: -144px -144px;
  width: 16px;
  height: 16px;
}