.container {
    width: 610px;
}

.listItem {
    display: grid;
    font-size: 12.5px;
    white-space: break-spaces;
    grid-template-columns: 62px 256px 206px;
    width: 100%;
}

.listItem:last-child {
    padding-bottom: 0;
    border-bottom: 0;
}

.listItem:first-child {
    padding-top: 0;
}

.Cell {

}