:root {
    --color-bg-bar: #1E222D;
    --color-bg-bar-hover: rgba(2, 4, 9, 0.2);
    --color-white-hover: #f8f8f8;
    --color-text: rgb(35, 43, 57);
    --color-text-hover: rgba(35, 43, 57, 0.6);
    --color-text-muted: #5D6671;
    --color-im-sent-: #536882;
    --color-im-received: #404E62;
    --color-bg-im-messages: #2C343F;
    --color-fb-message: #739cff;
    --color-fb-if: #facc48;
    --color-fb-action: #429122;
    --color-fb-timer: rgba(240, 132, 5, 1);
    --color-fb-note: #FEF6DE;
    --color-selection-primary: rgba(27, 110, 213, 0.5);
    --color-selection-lighter: rgba(27, 110, 213, 0.3);
    --color-fb-endpoint-hover: #ffc835;
    --color-bg-fb-action: rgba(216, 248, 225, 1);
    --color-bg-fb-if: rgba(249, 245, 200, 1);
    --color-primary: rgba(39, 123, 251, 1);
    --color-primary-focus: rgba(39, 123, 251, .9);
    --color-primary-hover: rgba(39, 123, 251, .8);
    --color-ok: rgba(245, 146, 49, 1);
    --color-ok-hover: rgba(214, 123, 35, 1);
    --color-secondary: rgba(249, 249, 249, 1);
    --color-secondary-hover: rgba(225, 225, 225, 1);
    --color-success: rgba(3, 144, 29, 1);
    --color-success-hover: rgba(2, 101, 21, 1);
    --color-destructive: rgba(224, 94, 102, 1);
    --color-destructive-hover: rgba(177, 61, 68, 1);
    --color-bg-container: #e6edee;
    --color-bg-fb-block-item: rgba(247, 248, 249, .7);
    --color-small-primary-shadow: rgba(65, 37, 87, 0.2);
    --color-border: #DDD;
    --border-radius: 4px;
    --box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.08);
    --height-base: 34px;
    --im-input-height: 3.5rem;
}