.wrapper {
    padding: 16px;
    display: flex;
}

.banner {
    display: none;
    width: 150px;
    height: 150px;
    min-width: 150px;
    border-radius: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.bodyWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
}

@media (max-width: 576px) {
    .banner {
        display: block;
        width: 100%;
        height: 140px;
        margin-bottom: 16px;
    }

    .wrapper {
        flex-direction: column;
    }
}