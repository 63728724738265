.text-center {
    text-align: center;
}

.text-muted {
    color: var(--text_secondary)
}

.word-break {
    word-break: break-word;
}

.cursor-pointer {
    cursor: pointer;
}

.flex-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fs-small {
    font-size: var(--vkui--font_footnote_caps--font_size--regular,13px);
    line-height: var(--vkui--font_footnote_caps--line_height--regular,16px);
}

.d-flex {
    display: flex;
}

.w-100 {
    width: 100%;
}

.ms-1 {
    margin-left: .25rem;
}

.me-1 {
    margin-right: .25rem;
}

.action-btn-multiline .vkuiButton__in {
    padding-top: 12px;
    padding-bottom: 12px;
}

.action-btn-multiline .vkuiButton__content {
    white-space: normal;
}

.hover-accent:hover {
    color: var(--accent);
}

.border-accent {
    border: 1px solid var(--accent);
}

.header-no-uppercase .vkuiHeader__content {
    text-transform: none;
}

.Button__content {
    font-weight: 400!important;
}