.container {
    /*подогнано под картинку чтобы было у нее 510px и 100%*/
    width: 616px;
}

.list {
    padding: 12px 20px 15px;
}

.listItem {

}

.listItem__icon {
    background: white;
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
    height: 128px;
    width: 488px;
    border: 1px solid var(--separator_common);
    padding: 0;
}

.icon__info {
    display: grid;
    justify-items: center;
    align-items: center;
    margin-bottom: 5px;
    white-space: break-spaces;
}

.list__addBtn {
    margin-top: 15px;
    text-align: center;
}

.hidden {
    display: none;
}
