.container {
    width: 99%;
    max-width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 80px;
    margin-bottom: 10px;
    white-space: normal;
    overflow: visible;
}

.container__input {
    grid-column: 1/3;
    padding-right: 30px;
    user-select: auto;
}

.container__input::-webkit-scrollbar {
    display: none;
}

.container__input::-webkit-scrollbar-button {
    display: none;
}

.container__limitModal {
    grid-column: 2/2;
    justify-self: right;
    padding-top: 10px;
}

.container__limitBlock {
    grid-column: 2/2;
    justify-self: right;
    padding-top: 10px;
    /*color: #000*/
}

.container__emojiModal {
    position: absolute;
    right: 5px;
    top: 6px;
    z-index: 2;
}

.container__emojiBlock {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 2;
}

.emoji__list {
    position: absolute;
    top: 25px;
    right: 15px;
    display: block;
    z-index: 1000;
    width: 250px;
    height: 200px;
    background: white;
    border: 1px solid silver;
    border-radius: .3em;
    overflow: auto;
    text-align: center;
}

.emoji__img {
    margin-right: 3px;
}

.emoji__bg {
    display: inline-block;
    margin: 2px 2px;
    padding: 2px 4px;
}

.emoji__bg:hover {
    visibility: visible;
    background: #f0f2f5;
    border-radius: 3px;
    cursor: pointer;
}

.container__buttons {
    grid-column: 1/1;
    grid-row: 2/2;
    /*padding-left: 5px;*/
    margin-left: -5px;
}

.lineBtns__mainBtn {
    text-transform: none;
    grid-column: 1/2;
    width: 110px;
    height: 46px;
    color: #202d3d;
    font-size: 12px;
    font-weight: 600;
    line-height: 26px;
    display: flex;
    margin-top: 10px;
    padding: 9px 14px;
    transition: .2s linear;
    text-decoration: none;
    border: 1px solid #e0e3eb;
    border-radius: 4px;
    background: #fff;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
}

.btnsLeft {
    text-align: start;
    margin-left: 0;
    margin-right: 0;
}

.lineBtns__mainBtn__active {
    color: #0097fd;
    border-color: #0097fd;
}

.lineBtns {
    display: grid;
    grid-template-columns: 115px 1fr;
}

.lineBtns__compactList__activate {
    grid-template-columns: none;
    position: relative;
    margin-bottom: 120px;
}

.lineBtns__compactList__deactivate {
    grid-template-columns: none;
    position: relative;
    margin-bottom: 0;
}

.lineBtns__childBtnContainer {
    display: inline-block !important;
    color: #fff;
    font-size: 9px;
    font-weight: 700;
    line-height: 27px;
    margin: 8px 0 0 7px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: left;
}

.lineBtns_childBtn {
    margin-right: 10px;
    margin-bottom: 5px;
    display: inline-block;
    background: #0097fd;
    padding: 0 12px;
    border-radius: 3px;
    cursor: pointer;
}
