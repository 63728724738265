.list {
    padding: 12px 8px;
}

.Cell {
    border-bottom: 1px solid var(--separator_common);
    padding-bottom: 10px;
}

.Cell:last-child {
    border-bottom: 0;
    padding-bottom: 0;
}

.listItem {
    position: relative;
    display: grid;
    font-size: 13px;
    grid-template-columns: 62px 430px;
}

.listItem:last-child {
    padding-bottom: 0;
    border-bottom: 0;
}

.listItem:first-child {
    padding-top: 0;
}

.listItem__icon {
    display: grid;
    align-items: center;
    justify-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid var(--separator_common);
    color: var(--text_subhead);
    cursor: pointer;
}

.listItem__content {
    line-height: 19px;
}