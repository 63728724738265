.textLink, .footerText {
    color: var(--text_link);
}

.textLink, .textTitle, .footerText, .textDescr, .time, .address {
    font-size: 13px;
    cursor: pointer;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.textDescr, .time, .address {
    color: var(--text_subhead);
}

.textDescr {
    line-height: 20px;
}

.fullDescription {
    cursor: pointer;
    padding-top: 9px;
    word-break: break-word;
}

.FlatButton {
    --icon-weight-margin: -4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    font-family: inherit;
    padding: 0 16px;
    min-width: 118px;
    max-width: 218px;
    cursor: pointer;
    border-radius: 4px;
    color: var(--button_primary_foreground);
    background-color: var(--button_primary_background);
    margin-top: 10px;
    height: 30px;
}

.FlatButton__in {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: inherit;
}

.FlatButton__content {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    margin-bottom: 1px;
}

.FlatButton:hover, .FlatButton:focus {
    opacity: .88;
    text-decoration: none;
}

.address, .time {
    padding-top: 5px;
    padding-left: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.address::before, .time::before {
    display: block;
    position: absolute;
    content: '';
    width: 15px;
    height: 15px;
    margin: 2px 0 0 -24px;
}

.address::before {
    background: url(https://vk.com/images/icons/group_info_cons.png) no-repeat 0 -60px;
}

.time::before {
    background: url(https://vk.com/images/icons/group_info_cons.png) no-repeat 0 -20px;
}

.footerText {
    padding: 0 28px;
    margin: 0 -28px;
    display: inline-block;
    overflow: hidden;
    max-width: 100%;
    vertical-align: top;
    text-overflow: ellipsis;
    box-sizing: border-box;
}

.footerText::after {
    margin-left: 5px;
    background: url(https://vk.com/images/icons/search_arrow.png) no-repeat 0 0;
    display: inline-block;
    content: '';
    height: 11px;
    width: 6px;
    vertical-align: middle;
}

.footerContainer {
    text-align: center;
    padding: 13px 0 14px;
    cursor: pointer;
    background-color: inherit;
    border-top: 1px solid var(--separator_common);
    border-radius: 0 0 4px 4px;
    transition: background-color 40ms linear
}

.footerContainer:hover {
    background-color: var(--background_hover);
}