.list {
    box-sizing: border-box;
    position: relative;
    display: grid;
    grid-auto-flow: column;
    justify-items: center;
    align-items: center;
    grid-column-gap: 15px;
    width: 100%;
    overflow-x: auto;
    margin: 10px auto;
    padding: 12px 25px 15px;
}

.tilesItem {
    position: relative;
    width: 160px;
    display: inline-block;
}

.avatar {
    cursor: pointer;
    display: grid;
    align-items: center;
    justify-items: center;
    border: 1px solid var(--separator_common);
    color: var(--text_subhead);
    width: 160px;
    height: 240px;
}

.avatar_square {
    height: 150px;
}

.deleteButton {
    position: absolute;
    right: -14px;
    top: -14px;
}

.addNewRowBtn__avatar {
    display: grid;
    align-items: center;
    justify-items: center;
    width: 56px;
    height: 56px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #f2f3f5;
    color: #7a838e;
}

.title {
    text-align: left;
    font-size: 12.5px;
    line-height: 15px;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    vertical-align: top;
    color: #2a5885;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    padding-top: 7px;
    /*text-decoration: underline;*/
    cursor: pointer;
}

.tiles__info {
    text-align: center;
    font-size: 13.5px;
    font-style: italic;
    margin-top: 10px;
    margin-bottom: 5px;
}

.tiles__info:nth-child(2n) {
    margin-top: 0;
    margin-bottom: 0;
}

.descr {
    direction: ltr;
    text-align: left;
    line-height: 15px;
    cursor: pointer;
    color: #7e7e7e;
    font-size: 12px;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    vertical-align: top;
    padding-top: 1px;
}

.btn {
    text-align: left;
    font-size: 12.5px;
    line-height: 15px;
    color: #2a5885;
    text-decoration: none;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    vertical-align: top;
    cursor: pointer;
    padding-top: 3px;
}

.addNewRowBtn {
    vertical-align: center;
    cursor: pointer;
    margin-bottom: 60px;
}
