.wrapper {
    padding-bottom: 3rem;
}

.title {
    font-weight: 600;
    font-size: 22px;
    text-align: center;
}

.descriptionWrapper {
    display: flex;
    justify-content: center;
}

.description {
    word-break: break-word;
    font-size: 14px;
    line-height: 1.5em;
    max-width: 500px;
}

.banner img {
    width: 100%;
}