.container {
    margin: 40px auto 0;
    width: 600px;
    font-size: 13px;
    border-radius: 4px;
    user-select: text
}

.header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 15px;
    margin: 10px 0;
}

.header__editBtn {
    /*color: #3f8ae0;*/
    font-weight: unset;
    cursor: pointer;
    justify-self: right;
}

.info {
    float: right;
    margin-right: 20px;
    padding-top: 2px;
    /*color: #939393;*/
    height: 32px;
    line-height: 40px;
}

.container__titleLink {
    cursor: pointer;
}

.container__titleContent {
    max-width: 100%;
    white-space: nowrap;
    padding: 2px 20px 0;
    height: 32px;
    line-height: 40px;
    overflow: hidden;
    font-weight: 400;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    font-size: 13px;
    letter-spacing: 0;
    outline: 0;
    /*color: black;*/

}

.titleContent__text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;

}

.titleContent__count {
    padding: 0 6px;
    /*color: #939393;*/
}

.container__descriptionBlock {
    text-align: center;
    padding: 12px 20px 15px;
}

.descriptionBlock__text {
    font-size: 19px;
    /*color: black;*/
    line-height: 28px;
    margin-bottom: 10px;
    white-space: break-spaces;
}

.descriptionBlock__description {
    font-size: 13px;
   /* color: #656565;*/
    line-height: 20px;
    margin-bottom: 33px;
    white-space: break-spaces;
}

.container__footer {
    font-weight: 400;
    -webkit-font-smoothing: subpixel-antialiased;
    direction: ltr;
    font-size: 12.5px;
    zoom: 1;
    white-space: nowrap;
    outline: 0;
    vertical-align: top;
    line-height: 15px;
    text-decoration: none;
    box-sizing: border-box;
    margin: 0;
    text-align: center;
    border: 0;
    display: block;
    /*color: #2a5885;*/
    transition: background-color 40ms linear;
    padding: 13px 0 14px;
    cursor: pointer;
    /*background-color: #fff;
    border-top: 1px solid #e7e8ec;*/
    border-radius: 0 0 4px 4px;
}

.container__footer:hover {
    background-color: #343434;
}

.clearFix::after {
    clear: both;
    content: '.';
    display: block;
    height: 0;
    font-size: 0;
    line-height: 0;
    visibility: hidden;
}

.buttonsContainer {
    display: grid;
    grid-column-gap: 15px;
    width: 600px;
    margin: 30px auto;
    grid-template-columns: 1fr 1fr;
}

.cardImageContainer {
    word-break: break-word;
    font-size: 16px;
    cursor: pointer;
    /*color: #3f8ae0;
    background: #f7f7f7;*/
    width: 140px;
    text-align: center;
    line-height: 98px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardImageContainer > img {
    width: 144px;
    height: 120px;
}

.List_widgets {
    padding-bottom: 40px;
}

.list__item {
    display: flex;
    /*align-self: center;*/
}

.Item__Cell {
    display: inline-block;
    cursor: pointer;
}

.Item__Cell:active {
    /*background-color: #d7d8d9;*/
    transition: 0.3s;
}

.activeItem {
    border: 1px solid green;
}

.footer__container__editMode {
    height: 50px;
    line-height: 50px;
}

.footer__deleteBtn__editMode {
    display: inline-block;
    line-height: 16px;
    /*color: #818c99;*/
}

.footer__enableCheckboxBtn__editMode {
    display: inline-block;
    margin-right: 10px;
    width: 35%;
    line-height: 16px;
}

.modalContainer {
    text-align: center !important;
}

.modalContainerIcon {
    display: inline-block;
    padding-right: 20px;
}

.buttonFileChoose {
    padding: 10px;
    margin-top: 20px;
}